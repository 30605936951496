import Vue from 'vue'
import VueRouter from 'vue-router'
import OktaVue, { LoginCallback } from '@okta/okta-vue'
import { OktaAuth } from '@okta/okta-auth-js'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const config = {
  clientId: process.env.VUE_APP_OKTA_CLIENTID,
  issuer:  "https://" + process.env.VUE_APP_OKTA_DOMAIN + "/oauth2/default",
  redirectUri: process.env.VUE_APP_LOGIN_REDIRECT,
  scopes: ['openid'],
  pkce: true
}
const oktaAuth = new OktaAuth(config)
Vue.use(OktaVue, { oktaAuth })

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: true },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  { 
    path: '/login/callback', 
    component: LoginCallback,
    meta: { requiresAuth: false },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

export default router
