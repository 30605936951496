<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="orders"
      item-key="reference_number"
      class="elevation-1"
      :search="search"
      sortBy="ts_created"
      :sort-desc="[true]"
    >
        
      <template v-slot:item.ts_created="{ item }">
        <span v-if="item.ts_created!==''">{{ new Date(item.ts_created*1000).toLocaleString() }}</span>
      </template>

      <template v-slot:item.drone_id="{ item }">
        <span v-if="item.drone_id!==''">{{ item.drone_id }}</span>
        <span v-if="item.drone_id===''">
             <v-btn color="success" class="mr-6" @click="assign_drone(item)">Assign Drone</v-btn>
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <span v-if="item.status==='DA'">
             <v-btn color="success" class="mr-6" @click="update_state(item,'DPU')">Take off</v-btn>
        </span>
        <span v-if="item.status==='DPU'">
             <v-btn color="success" class="mr-6" @click="update_state(item,'DD')">Complete Delivery</v-btn>
        </span>
      </template>

      <template v-slot:item.distance="{ item }">
        <span v-if="item.dtogo!==undefined">{{ item.dtogo }}</span>
      </template>

      <template v-slot:item.del="{ item }">
        <v-btn color="error" class="mr-6" @click="del(item)">Delete</v-btn>
      </template>


      <template v-slot:top>
        <v-toolbar flat>
        <v-toolbar-title>Orders</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
    
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import axios from 'axios'

export default {
  name: "OrderTable",
  components: {
  },
  computed: {
    ...mapGetters(["orders", "accessToken"]),
  },
  data() {
    return {
      headers:[{text:'Reference Number',value:'reference_number', width:"150px"},
               {text:'Date/Time Created', value:'ts_created', width:"200px"},
               {text:'Order Status', value:'status', width:'200px'},
               {text:'Drone ID', value:'drone_id', width:'200px'},
               {text:'Actions', value:'actions', width:'200px'},
               {text:'Distance to dest (ft)', value:'distance', width:'200px'},
               // {text:'Delete', value:'del', width:'100px'}
               ],
      search: ''
    }
  },
  mounted() {
  },
  methods: {
    assign_drone(item) {
      let dest = { lat:item.destination.lat, lng:item.destination.lng } 
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .post(process.env.VUE_APP_API_BASE_URL+'assign/'+item.reference_number, 
              JSON.stringify(dest), {
                headers: {'Content-Type': 'application/json'}
              })
        .then((response) => {
          console.log(response)
          this.$store.dispatch('getOrders')
        })
        .catch((error) => console.log(error))
    },
    del(item) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .delete(process.env.VUE_APP_API_BASE_URL+'orders/'+item.reference_number, 
              JSON.stringify({}), {
                headers: {'Content-Type': 'application/json' }
              })
        .then((response) => {
          console.log(response)
          this.$store.dispatch('getOrders')
        })
        .catch((error) => console.log(error))
    },
    update_state(item, newstatus) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken
      axios
        .put(process.env.VUE_APP_API_BASE_URL+'orders/'+item.reference_number, 
              JSON.stringify({drone_id:item.drone_id, status:newstatus}), {
                headers: {
                      'Content-Type': 'application/json'
                }
              })
        .then((response) => {
          console.log(response)
          this.$store.dispatch('getOrders')
        })
        .catch((error) => console.log(error))
    },

}
}
</script>
<style scoped>

</style>
