var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"item-key":"reference_number","search":_vm.search,"sortBy":"ts_created","sort-desc":[true]},scopedSlots:_vm._u([{key:"item.ts_created",fn:function(ref){
var item = ref.item;
return [(item.ts_created!=='')?_c('span',[_vm._v(_vm._s(new Date(item.ts_created*1000).toLocaleString()))]):_vm._e()]}},{key:"item.drone_id",fn:function(ref){
var item = ref.item;
return [(item.drone_id!=='')?_c('span',[_vm._v(_vm._s(item.drone_id))]):_vm._e(),(item.drone_id==='')?_c('span',[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"success"},on:{"click":function($event){return _vm.assign_drone(item)}}},[_vm._v("Assign Drone")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status==='DA')?_c('span',[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"success"},on:{"click":function($event){return _vm.update_state(item,'DPU')}}},[_vm._v("Take off")])],1):_vm._e(),(item.status==='DPU')?_c('span',[_c('v-btn',{staticClass:"mr-6",attrs:{"color":"success"},on:{"click":function($event){return _vm.update_state(item,'DD')}}},[_vm._v("Complete Delivery")])],1):_vm._e()]}},{key:"item.distance",fn:function(ref){
var item = ref.item;
return [(item.dtogo!==undefined)?_c('span',[_vm._v(_vm._s(item.dtogo))]):_vm._e()]}},{key:"item.del",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-6",attrs:{"color":"error"},on:{"click":function($event){return _vm.del(item)}}},[_vm._v("Delete")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Orders")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }