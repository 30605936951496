import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    accessToken: '', 
    orders: []
  },
  mutations: {
    setAccessToken(state, accessToken) {
      state.accessToken = accessToken
    },
    setOrders(state, orders) {
      state.orders = orders
    }
  },
  getters: {
    accessToken: state => state.accessToken,
    orders: state => state.orders
  },
  actions: {
    getOrders({ commit }) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.state.accessToken
      axios.get(process.env.VUE_APP_API_BASE_URL+'orders')
        .then((response) => {
          let orders = response.data['orders']
          commit('setOrders', orders)
          // for (let i=0; i<this.flightlogs.length; i++) {
            // let d = this.flightlogs[i]
          // }
        })
        .catch((error) => console.log(error))
    }
  },
  modules: {
  },
  methods: {
  }
})
