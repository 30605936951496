<template>
  <div>
      <OrderTable/>
  </div>
</template>

<script>
import OrderTable from '../components/OrderTable.vue'

  export default {
    name: 'Home',
    components: {
      OrderTable
    },
    mounted() {
      this.startup()
    },
    methods: {
      async startup() {
        const accessToken = await this.$auth.getAccessToken()
        console.log('accessToken: ',accessToken)
        this.$store.commit('setAccessToken', accessToken)
        this.$store.dispatch('getOrders')
        setInterval(() => {
          this.$store.dispatch('getOrders')
        }, 5000)
      }
    },
  }
</script>
